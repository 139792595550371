import { Dispatch } from "@reduxjs/toolkit"

export enum UserActions {
    UPDATE_STATUS   = "UserUpdateStatus",
    UPDATE_DATA     = "UserUpdateData",
    LOGOUT          = "UserLogout",
    ALL             = "UserUpdate"
}

interface UserStatusUpdatePayload {
    type: UserActions.UPDATE_STATUS,
    payload: IUserPayloadStatus
}
export const updateUserStatus = (payload: IUserPayloadStatus) => {
    return (dispatch: Dispatch<UserActionTypes>) => {
        dispatch({
            type: UserActions.UPDATE_STATUS,
            payload: {...payload}
        })
    }
}


interface UserDataUpdatePayload {
    type: UserActions.UPDATE_DATA,
    payload: IUserPayloadData
}
export const updateUserData = (payload: IUserPayloadData) => {
    return (dispatch: Dispatch<UserActionTypes>) => {
        dispatch({
            type: UserActions.UPDATE_DATA,
            payload: {...payload}
        })
    }
}

interface UserLogout {
    type: UserActions.LOGOUT
}
export const userLogout = () => {
    return (dispatch: Dispatch<UserActionTypes>) => {
        dispatch({
            type: UserActions.LOGOUT
        })
    }
}

interface UpdateUser {
    type: UserActions.ALL,
    payload: IUserPayload
}
export const updateUser = (payload: IUserPayload) => {
    return (dispatch: Dispatch<UserActionTypes>) => {
        dispatch({
            type: UserActions.ALL,
            payload: {...payload}
        })
    }
}

export type UserActionTypes = 
    UserStatusUpdatePayload | 
    UserDataUpdatePayload | 
    UpdateUser |
    UserLogout