import { Input, Box, Text, FormControl, InputProps, FormLabel, InputGroup, InputLeftElement, Flex } from '@chakra-ui/react'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import { SearchIcon } from '@chakra-ui/icons'
import LocationIcon from '../assets/LocationIcon'

interface IInput {
    value: string, 
    setValue: (update: Partial<ILocation>) => void,
    label: string,
    placeholder?: string,
    isRequired?: boolean,
    isDisabled?: boolean,
    size?: InputProps['size'],
    variant?: InputProps['variant']
}

function InputPlacesAutocomplete({ value, setValue, label, isRequired, isDisabled, size, variant='filled', placeholder }: IInput) {

    const handle_select = async (address: string, placeID: string)=> {
        const results = await geocodeByAddress(address)
        const latLng = await getLatLng(results[0])
        setValue({
            description: address,
            coordinates: [latLng.lat, latLng.lng]  
        })
    }

    return (
        <PlacesAutocomplete
            value={value}
            onChange={(e) => setValue({description: e})}
            onSelect={handle_select}
            debounce={700}
            shouldFetchSuggestions={value.length > 3}
        >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
            <Box>
                <FormControl isRequired={isRequired} isDisabled={isDisabled}>
                    <FormLabel mb={0.5}>{label}</FormLabel>
                    <InputGroup>
                    <InputLeftElement color='gray.400' children={<SearchIcon />} />
                    <Input 
                        size={size}
                        variant={variant}
                        {...getInputProps({ placeholder: placeholder })} 
                    />
                    </InputGroup>
                </FormControl>

                <Box 
                    position={'absolute'}
                    bg='white'
                    zIndex={100}
                    borderRadius='md'
                    boxShadow={'md'}
                    p={1}
                    hidden={suggestions.length === 0}
                >
                    {loading ? <Text>Loading...</Text> : null}

                    {
                        suggestions.map((suggestion, key) => {
                            const style = { 
                                cursor:"pointer",
                            }

                            return <Flex 
                                {...getSuggestionItemProps(suggestion, {style})}
                                key={key}
                                bg={suggestion.active ? 'gray.100' : ''}
                                borderRadius='md'
                                p={2}
                                _hover={{bg:'gray.100'}}
                                alignItems='center'
                                gap={2}
                            >
                                <LocationIcon width={'20px'} />
                                {
                                    suggestion.description
                                }
                            </Flex>
                        })
                    }
                </Box>
            </Box>
        )}
        </PlacesAutocomplete>
    )
}

export default InputPlacesAutocomplete