import { Box, Button, ButtonGroup, Menu, MenuButton, MenuList, useBreakpointValue, IconButton } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { AddIcon, ChevronDownIcon, HamburgerIcon } from '@chakra-ui/icons'
import NavItem from '../../components/NavItem'

function GuestMenu() {

    const navigate = useNavigate()

    const isMobile = useBreakpointValue({ base: true, md: false})

    return (
        <Box>
            <ButtonGroup variant='ghost' gap={0.1}>
                {
                    isMobile
                    ?   <Menu flip={true} >
                            <MenuButton as={IconButton} icon={<HamburgerIcon />} />
                            <MenuList>
                                <NavItem>About Us</NavItem>
                                <NavItem>Create Event</NavItem>
                            </MenuList>
                        </Menu>
                    :   <>
                            <Menu>
                                <MenuButton as={Button} rightIcon={<ChevronDownIcon ml={-1}  />}>
                                    About Us
                                </MenuButton>
                                <MenuList>
                                    <NavItem>Why us?</NavItem>
                                    <NavItem>Pricing</NavItem>
                                    <NavItem>Blog</NavItem>
                                </MenuList>
                            </Menu>

                            <Button leftIcon={<AddIcon />} onClick={(() => navigate('/login'))} >
                                Create Event
                            </Button>
                        </>
                }
                

                <Button onClick={() => navigate('/login')}>
                    Log In
                </Button>

                <Button colorScheme='purple' onClick={() => navigate('/signup')} >
                    Sign Up
                </Button>

            </ButtonGroup>
        </Box>
    )
}

export default GuestMenu