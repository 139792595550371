import React from 'react'
import { Box, Center } from '@chakra-ui/react'

function Home() {
  return (
    <Box>
      <Box maxW='100%x' h='60vh' bg='red.100' ></Box>

      <Center flexDirection={'column'} w='100%' px={10} >
        <Box minW='100%' h='200px' bg='blue.100' m={3} ></Box>
        <Box minW='100%' h='200px' bg='blue.100' m={3} ></Box>
        <Box minW='100%' h='200px' bg='blue.100' m={3} ></Box>
      </Center>

    </Box>
  )
}

export default Home