import React from 'react'
import GoogleIcon from '../assets/GoogleIcon'
import { Button } from '@chakra-ui/react'

interface IGoogleButton {
    isDisabled: boolean,
    onClick: () => void,
    children: React.ReactNode
}

function GoogleButton({isDisabled, onClick, children}: IGoogleButton) {
  return (
        <Button 
            isDisabled={isDisabled} 
            onClick={onClick} 
            width='240px'
            height={'50px'}
            bg='white'
            color={'#444'}
            borderRadius='5px'
            border={'thin solid #888'}
            boxShadow='1px 1px 1px grey'
            whiteSpace={'nowrap'}
            fontFamily='Roboto, sans-serif'
            fontWeight={'bold'}
            fontSize='14px'
            px={'42px'}
            verticalAlign='middle'
            justifyContent={'center'}
            alignItems='center'
            leftIcon={
                <GoogleIcon 
                    width='30px'
                />
            }
        >
            {children}
        </Button>
  )
}

export default GoogleButton