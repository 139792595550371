import { Routes, Route } from 'react-router-dom'
import App from '../App'
import LogIn from '../auth/LogIn'
import SignUp from '../auth/SignUp'
import PrivateRoute from '../containers/PrivateRoute'
import ManageBoard from '../views/Manage/ManageBoard'

function MainRouter() {
  return (
    <Routes>

        <Route path='*' element={<App />} />

        <Route path='signup' element={
            <PrivateRoute reverse={true} redirect='/' >
              <SignUp />  
            </PrivateRoute>
          } 
        />
        
        <Route path='login' element={
            <PrivateRoute reverse={true} redirect='/'>
              <LogIn />
            </PrivateRoute>
          } 
        />

        <Route path='manage/*' element={
            <PrivateRoute redirect='/' >
              <ManageBoard />
            </PrivateRoute>
          } 
        />

    </Routes>
  )
}

export default MainRouter