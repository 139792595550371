import React, { useState } from 'react'
import { Box, Heading, Divider, Text, Center } from '@chakra-ui/react'
import InputForm from '../../../../components/InputForm'
import { useCreateEvent } from './useCreateEvent'
import TextAreaForm from '../../../../components/TextAreaForm'
import LocationForm from './LocationForm'
import DateAndTimeForm from './DateAndTimeForm'

function CreateEvents() {

  const { state, setState, setLocation } = useCreateEvent() 

  return (
    <Box>
      <Heading>Create Events</Heading>

      <Box p={5} borderWidth='1px' borderRadius={'lg'} >

        <form>
          {/*  BASIC INFORMATION  */}
          <Box>
            <Heading size='lg'>Basic Information</Heading>
            <Text>Basic information about the event</Text>

            <InputForm 
              label='Event Name'
              placeholder='Name'
              value={ state.name }
              onChange={(value) => setState({name: value})}
            />
            <TextAreaForm
              label='Description'
              placeholder='Description'
              value={ state.description }
              onChange={(value) => setState({description: value})}
            />
          </Box>
          
          <Divider my={10} />
      
          {/*  LOCATION  */}
          <Box>
            
            <Heading size='lg'> Location </Heading>
            <Text>Place where the event will be held at</Text>

            <LocationForm 
              locationDescription={state.location.description}
              setValue={setLocation}
            />

          </Box>

          <Divider my={10} />

          {/*  DATE AND TIME  */}
          <Box>
            <Heading size='lg'> Date and Time </Heading>

            <DateAndTimeForm />        

          </Box>
        </form>

      </Box>

    </Box>
  )
}

export default CreateEvents