import React from 'react'
import { useRadio, Box, RadioProps, chakra } from '@chakra-ui/react'

interface IRadioCard {
    children: string,
    radioProps?: RadioProps
}

function RadioCard({ children, radioProps }: IRadioCard) {

    const { getInputProps, getCheckboxProps } = useRadio(radioProps)

    const input = getInputProps()
    const checkbox = getCheckboxProps()

    return (
        <chakra.label >
            <input {...input} hidden />
            <Box
                {...checkbox}
                cursor='pointer'
                borderWidth='1px'
                borderRadius='md'
                boxShadow='md'
                _checked={{
                    bg: 'purple.600',
                    color: 'white',
                    borderColor: 'purple.600',
                }}
                _focus={{
                    boxShadow: 'outline',
                }}
                px={5}
                py={3}
            >
                {children}
            </Box>
        </chakra.label>
    )
}

export default RadioCard