import React from 'react'
import DatePicker, { DateObject, CalendarProps, DatePickerProps, Value } from "react-multi-date-picker"
import "react-multi-date-picker/styles/layouts/mobile.css"
import './DatePicker.css'
import { FormControl, FormLabel, useBreakpointValue, FormControlProps } from '@chakra-ui/react'

export interface ICustomDatePicker {
  label: string,
  calendarProps: CalendarProps & DatePickerProps,
  formControlProps? : FormControlProps
}

function CustomDatePicker({ label, calendarProps, formControlProps }: ICustomDatePicker) {

  const isMobile = useBreakpointValue({base:true, md: false})

  return (
    <FormControl {...formControlProps} >
      <FormLabel mb={0.5}>{label}</FormLabel>
      <DatePicker 
        numberOfMonths={isMobile ? 1 : 2}
        format='MM/DD/YYYY'
        value={calendarProps.value}
        onChange={calendarProps.onChange}
        inputMode='none'
        className={isMobile ? 'rmdp-mobile purple' : 'purple'}

        // Input styles
        style={datePickerInputStyles}
     
        {...calendarProps}
      />

    </FormControl>
  )
}


export const datePickerInputStyles: React.CSSProperties = {
  outline:'2px solid transparent',
  outlineOffset: '2px',
  position:'relative',
  height: "var(--chakra-sizes-10)",
  background:'var(--chakra-colors-gray-100)',
  borderRadius: "var(--chakra-radii-md)",
  borderColor:'var(--chakra-colors-transparent)',
  fontSize: 'var(--chakra-fontSizes-md)',
  padding: "3px 10px",
  transitionProperty: 'var(--chakra-transition-property-common)',
  transitionDuration: 'var(--chakra-transition-duration-normal)',
  width:'100%',
  minWidth:'200px'
}

export default CustomDatePicker