import React from 'react'
import { Box, Center, Heading, Grid, GridItem } from '@chakra-ui/react'

function Footer() {
  return (
    <Box
        // position={'absolute'}
        bottom={0}
        right={0}
        width='100%'
        bg='gray.800'
        color='white'
        mt={2}
    >
        <Center flexDir={'column'}>
        
            <Heading>This is a Footer</Heading>

            <Grid templateColumns={'repeat(5, 1fr)'} gap={2}>
                <GridItem>A</GridItem>
                <GridItem>B</GridItem>
                <GridItem>C</GridItem>
                <GridItem>D</GridItem>
            </Grid>

        </Center>
    </Box>
  )
}

export default Footer