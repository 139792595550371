import { Routes, Route } from 'react-router-dom'
import Home from '../views/Home'

function AppRouter() {
  return (
    <Routes>
      
      <Route index element={<Home />} />

    </Routes>
  )
}

export default AppRouter