import React from 'react'
import { Box, Button, Text, Flex } from '@chakra-ui/react'
import { CalendarIcon, DragHandleIcon } from '@chakra-ui/icons'

interface IButton {
    icon: React.ReactElement,
    label: string,
    onClick?: () => void
}

function LargeIconButton({icon, label, onClick = () => {}}: IButton) {
  return (
    <Flex 
        flexDir='column' 
        alignItems={'center'} 
        py={3} 
        borderRadius={'md'}
        transition='background-color 0.2s ease'
        cursor='pointer'
        _hover={{
            bg: 'gray.100',
            transition:'background-color 0.2s ease'
        }}
        _active={{
            bg:'gray.200'
        }}
        userSelect='none'
        onClick={onClick}
    >
            
        {icon}
        <Text mt={2}>{label}</Text>

    </Flex>
  )
}

export default LargeIconButton