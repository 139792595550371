import React, { useState } from 'react'
import { Center, Heading, Text, Box } from '@chakra-ui/react'
import InputPlacesAutocomplete from '../../../../components/InputPlacesAutocomplete'
import RadioGroup from '../../../../components/CustomRadio/RadioGroup'

interface ILocationForm {
    locationDescription: string,
    setValue: (update: Partial<ILocation>) => void
}

function LocationForm({ locationDescription, setValue }: ILocationForm) {
    const [ typeLocation, setTypeLocation ] = useState("Place")

    return (
        <Box>
            <Center>
                <RadioGroup
                    options={LocationOptions}
                    defaultValue={'Place'}
                    value={typeLocation}
                    name="LocationType"
                    onChange={setTypeLocation}
                />
            </Center>

        {
            (() => {
                switch (typeLocation) {
                    case 'Place':
                        return <Box>
                            <InputPlacesAutocomplete 
                                label='Location'
                                placeholder='Search for location or place'
                                value={locationDescription}
                                setValue={setValue}
                            />
                        </Box>
                    
                    case 'Online':
                        return <Box>

                        </Box>

                    case 'TBA':
                        return <Box>
                            <Text>You will be able to set the location later.</Text>
                        </Box>

                    default:
                        return null
                }
            })()
        }

        </Box>
    )
}

const LocationOptions = ['Place', 'Online', 'TBA']

export default LocationForm