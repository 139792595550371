import './App.css';
import { Box, BoxProps, Button } from '@chakra-ui/react';
import AppRouter from './Routers/AppRouter';
import Footer from './app/Footer';
import Header from './app/header/Header';
import StickyContainer from './containers/StickyContainer';
import { AddIcon } from '@chakra-ui/icons'

function App() {
  return (
    <Box pt={1}>

      <StickyContainer 
        style={(isSticky) => ({
          bg: isSticky ? 'white' : 'transparent',
          boxShadow: isSticky ? 'md' : ''
        })} 
      >
        <Header other={
          <Button colorScheme='purple' leftIcon={<AddIcon />} >
            Create Event
          </Button>
        } />
      </StickyContainer>

      <Box {...containerStyles}>
      
        <AppRouter />
      
      </Box>
      
      <Footer />

    </Box>
  );
}

const containerStyles: BoxProps = {
  minH:'100vh',
  padding: 2,
  mb:5
  // bg:'green.100'
}

export default App;
