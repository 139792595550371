import { UserActions, UserActionTypes } from "../actions/user.actions";
import { getPayloadFromToken } from "../../utils/getPayloadFromToken";

const initialUser = (): IUserState => {
    let token = localStorage.getItem('token')
    if (token){
        let user = getPayloadFromToken(token)
        return {
            data: {
                _id: user._id,
                first_name: user.first_name,
                last_name: user.last_name,
                email:user.email
            },
            status:{
                isLoading: false,
                isLoggedIn: true
            }
        }
    }
    else {
        return {
            data:{
                _id:'',
                first_name: "",
                last_name: "",
                email: ""
            },
            status:{
                isLoading: false,
                isLoggedIn: false
            }
        }
    }
}

export const UserReducer = (state: IUserState = initialUser(), action: UserActionTypes) => {

    switch (action.type) {
        case UserActions.UPDATE_STATUS:
            return {
                ...state,
                status:{
                    ...state.status,
                    ...action.payload
                }
            }
        case UserActions.UPDATE_DATA:
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.payload
                }
            }
        case UserActions.LOGOUT:
            localStorage.clear()
            return initialUser()
        
        case UserActions.ALL:
            return {
                ...state,
                data: {...state.data, ...action.payload.data},
                status: {...state.status, ...action.payload.status}
            }
        default:
            return state;
    }
}

