import React, { useState } from 'react'
import RadioGroup from '../../../../components/CustomRadio/RadioGroup'
import { Box, Center, Input } from '@chakra-ui/react'
import CustomDatePicker from '../../../../components/DatePicker/CustomDatePicker'
import { DateObject } from 'react-multi-date-picker'
import CustomTimePicker from '../../../../components/DatePicker/TimePicker/CustomTimePicker'

function DateAndTimeForm() {

    const [ eventTiming, setTiming ] = useState("Single Event")
    const [ startDate, setStartDate ] = useState(new DateObject().set({minute:0}))
    const [ endDate, setEndDate ] = useState(new DateObject().set({minute: 0}).add(1, 'hours'))

    console.log(new DateObject().set({minute:0}))

    const handle_start = (date: DateObject) => {
        if (endDate < date){
            setEndDate(date)
        }
        setStartDate(date)
    }
    const handle_end = (date: DateObject) => setEndDate(date)

    return (
        <Box>
            <Center>  
                <RadioGroup
                    options={['Single Event', 'Recurring Event']}
                    defaultValue='Single Event'
                    value={eventTiming}
                    name='Type of event'
                    onChange={setTiming}
                />
            </Center>

            {
                (() => {
                    switch (eventTiming) {
                        case 'Single Event':
                            return <Box>
                                <CustomDatePicker 
                                    label='Start Date'
                                    calendarProps={{ 
                                        value: startDate,
                                        minDate: new Date(),
                                        onChange: handle_start
                                    }}
                                />   
                                <CustomDatePicker 
                                    label='End Date'
                                    calendarProps={{
                                        value: endDate,
                                        minDate: startDate,
                                        onChange: handle_end
                                    }}
                                />

                                <CustomTimePicker 
                                    label='Start Time'
                                    calendarProps={{
                                        value: startDate,
                                        onChange: handle_start,
                                        placeholder:'Select Start Time'
                                    }}
                                />

                                <CustomTimePicker 
                                    label='End Time'
                                    calendarProps={{
                                        value: endDate,
                                        onChange: handle_end,
                                        placeholder:'Select End Time'
                                    }}
                                />

                            </Box>
                    
                        case 'Recurring Event':
                            return <Box>
                                Recurring Event
                            </Box>

                        default:
                            return null
                    }
                })()
            }

        </Box>
    )
}

export default DateAndTimeForm