import { Route, Routes } from 'react-router-dom'
import PrivateRoute from '../containers/PrivateRoute'
import FinanceManager from '../views/Manage/FinanceManager/FinanceManager'
import ReportsManager from '../views/Manage/ReportsManager/ReportsManager'
import EventRouter from './EventRouter'

function ManageRouter() {
  return (
    <Routes>
        
        <Route path='*' element={<PrivateRoute redirect='/' ><EventRouter /></PrivateRoute>} />

        <Route path='/reports' element={<PrivateRoute redirect='/' ><ReportsManager /></PrivateRoute>} />
        
        <Route path='/finance' element={<PrivateRoute redirect='/' ><FinanceManager /></PrivateRoute>} />

    </Routes>
  )
}

export default ManageRouter