import { Box, Heading } from "@chakra-ui/react"

function FinanceManager() {
  return (
    <Box>
      <Heading>Finances</Heading>
    </Box>
  )
}

export default FinanceManager