import React from 'react'
import SideBarManageBoard from '../../app/sidebar/SideBarManageBoard'
import { Box } from '@chakra-ui/react'
import ManageRouter from '../../Routers/ManageRouter'

function ManageBoard() {
  return (
    <Box>
        <SideBarManageBoard>
            <Box h='100%' p={3}> {/* Container for manage content */}

              <ManageRouter />

            </Box>
        </SideBarManageBoard>
    </Box>
  )
}

export default ManageBoard