import React from 'react'
import RadioCard from './RadioCard'
import { useRadioGroup, HStack } from '@chakra-ui/react'

interface IRadioGroup {
    options: string[],
    defaultValue: string,
    name: string,
    value: string,
    onChange: (str: string) => void
}

function RadioGroup({ options, defaultValue, name, onChange, value }: IRadioGroup) {

    const { getRootProps, getRadioProps } = useRadioGroup({
        name: name,
        defaultValue: defaultValue,
        onChange: onChange,
        value: value
    })

    const group = getRootProps()

    return (
        <HStack {...group}>
            {
                options.map((value, key) => {
                    const radio = getRadioProps({ value })
                    return (
                        <RadioCard key={key} radioProps={{...radio}}>
                            { value }
                        </RadioCard>
                    )
                })
            }
        </HStack>
    )
}

export default RadioGroup