import React from 'react'
import { Box, Menu, MenuButton, MenuList, MenuDivider, Button, Flex } from '@chakra-ui/react'
import NavItem from '../../components/NavItem'
import { useNavigate } from 'react-router-dom'

interface IUserMenu {
    user: IUser,
    logOut: () => void
}

function UserMenu({ user, logOut }: IUserMenu) {

    const navigate = useNavigate()

  return (
    <Flex>
        <Menu>
            <MenuButton as={Button} variant={'ghost'} >
                {user.first_name}
            </MenuButton>
            <MenuList>
                <NavItem>Browse</NavItem>
                <NavItem onClick={() => navigate('/manage') } >Manage</NavItem>
                <MenuDivider />
                <NavItem onClick={logOut}>
                    Log Out
                </NavItem>
            </MenuList>
        </Menu>    
    </Flex>
  )
}

export default UserMenu