import { Stack, IconButton, Tooltip, Popover, PopoverTrigger, PopoverContent, PopoverBody, SimpleGrid, Box, useDisclosure } from '@chakra-ui/react'
import LargeIconButton from '../../components/LargeIconButton'
import AppsIcon from '../../assets/AppsIcon'
import CalendarIcon from '../../assets/CalendarIcon'
import ReportsIcon from '../../assets/ReportsIcon'
import CashIcon from '../../assets/CashIcon'
import { useNavigate } from 'react-router-dom'

interface IManageMenu {
    isMobile?: boolean
}

function ManageMenu({isMobile= false}: IManageMenu) {

    const navigate = useNavigate()
    const { onOpen, onClose, isOpen } = useDisclosure()

    if (isMobile){

        const handle_click = (direct:string) => {
            onClose()
            navigate(direct)
        }

        return (
            <Popover isOpen={isOpen} onClose={onClose}>

                <PopoverTrigger>
                    <IconButton 
                        aria-label='open manager menu' 
                        variant='ghost' 
                        icon={<AppsIcon width={6} />} 
                        onClick={onOpen}
                    />
                </PopoverTrigger>

                <PopoverContent>
                    <PopoverBody>
                        <SimpleGrid columns={2} gap={3}>
                        {
                            managePages.map((page, key) => (
                                <LargeIconButton 
                                    key={key}
                                    icon={page.icon}
                                    label={page.label}
                                    onClick={() => handle_click(page.direct)}
                                />
                            ))
                        }
                        </SimpleGrid>
                    </PopoverBody>
                </PopoverContent>

            </Popover>
        )
    }
    else {
        return (
          <Stack gap={6}>
      
              {
                  managePages.map((page, key) => (
                    <Box key={key}>
                      <Tooltip label={page.label} hasArrow placement='right' >
                        <IconButton 
                            variant='ghost'
                            aria-label={page.label} 
                            icon={page.icon} 
                            onClick={() => navigate(page.direct)}
                        />
                      </Tooltip>
                    </Box>
                  ))
              }
      
          </Stack>
        )
    }
}

export default ManageMenu

const managePages = [
    {
        label: "Events",
        direct: "",
        icon: <CalendarIcon width={6}/>
    },
    {
        label: "Reports",
        direct: "reports",
        icon: <ReportsIcon width={6}/>
    },
    {
        label: "Finance",
        direct: "finance",
        icon: <CashIcon width={6}/>
    },

]