import React from 'react'
import {  Heading, Spacer, Flex, Box } from '@chakra-ui/react' 
import UserMenu from './UserMenu'
import { useUser } from '../../Store/hooks/useUser'
import GuestMenu from './GuestMenu'
import { useNavigate } from 'react-router-dom'

interface IHeader {
  other?: React.ReactElement
}

function Header({ other }: IHeader) {
  
  const { user, userStatus, logOut } = useUser()
  const navigate = useNavigate()

  return (
    <Flex alignItems={'center'} px={4} py={2} >

      <Heading cursor='pointer' onClick={() => navigate('/')}>
        Events
      </Heading>

      <Spacer />

      {
        userStatus.isLoggedIn
        ? 
        <>
          <Box mx={2}>
            { other }
          </Box>
          <UserMenu  
            user={user}
            logOut={logOut}
          />
        </>
        : <GuestMenu />
      }
      
    </Flex>
  )
}

export default Header