import { useAuth } from './useAuth'
import { Box, Hide, Flex, Heading, Button, Center, Text, Link, Divider } from '@chakra-ui/react'
import InputForm from '../components/InputForm'
import { AuthErrorTypes } from '../interfaces/errors.d'
import AlertAbsolute from '../components/AlertAbsolute'
import { Link as RLink } from 'react-router-dom'
import GoogleButton from '../components/GoogleButton'

const LogInForm = () => {

    const { state, setState, submit, error, isLoading, googleOAuthURL } = useAuth(true)
    
    return (
        <Box>

            <AlertAbsolute 
                isOpen={ error===AuthErrorTypes.WRONG || error === AuthErrorTypes.UNKNOWN }
                title={
                    error===AuthErrorTypes.WRONG ? "No credentials were found" :error === AuthErrorTypes.UNKNOWN ? "Try using another log in method" : ""
                }
                status='error'
                alertProps={{borderRadius:6}}
            />

            <Heading>Log In</Heading>
            <Text>
                New to Events? 
                <Link ml={1} as={RLink} to='/signup' color='purple' >
                    Sign Up
                </Link>
            </Text>

            <form onSubmit={submit}>
                <Box my={5} >
                    <InputForm 
                        isRequired={true}
                        label='Email'
                        value={state.email}
                        placeholder='Enter Email'
                        onChange={(str) => setState({email: str})}
                        inputProps={{focusBorderColor:'purple.400'}}
                        containerProps={{
                            isInvalid: error === AuthErrorTypes.WRONG
                        }}
                    />
                </Box>

                <Box my={5}>
                    <InputForm 
                        isRequired={true}
                        label='Password'
                        type='password'
                        value={state.password}
                        placeholder='Enter Password'
                        onChange={(str) => setState({password: str})}
                        containerProps={{
                            isInvalid: error === AuthErrorTypes.WRONG
                        }}
                    />
                </Box>

                <Center>
                    <Button 
                        colorScheme='purple' 
                        type='submit' 
                        w={'50%'} 
                        isLoading={isLoading}
                    >
                        Log In
                    </Button>
                </Center>
            </form>

            <Divider my={5} />

            <Center>

                <GoogleButton 
                    isDisabled={!googleOAuthURL} 
                    onClick={() => {window.location.href = googleOAuthURL}} 
                >
                    Sign In with Google
                </GoogleButton>

            </Center>


        </Box>
    )
}

const LogIn = () => {
    return (
        <Flex minH='100vh'>
            
            <Box flex={1} p={5} >
                <Center>
                    <Box w={{base:'95%', md:'80%'}}>
                        <LogInForm />
                    </Box>
                </Center>
            </Box>

            <Hide below='md'>
                <Box flex={1}>
                    
                </Box>
            </Hide>

        </Flex>
    )
}

export default LogIn