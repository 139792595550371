import React from 'react'
import { MenuItem } from '@chakra-ui/react'

interface INavItem {
    children: React.ReactNode,
    onClick?: () => void
}

function NavItem({ children, onClick = () =>{} }: INavItem) {
  return (
    <MenuItem 
      onClick={onClick}
      py={3}
    >
        {children}
    </MenuItem>
  )
}

export default NavItem