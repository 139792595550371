import React from 'react'
import { useParams } from 'react-router-dom'

function ManageEventDetails() {

    const { eventID } = useParams()

  return (
    <div>ManageEventDetails</div>
  )
}

export default ManageEventDetails