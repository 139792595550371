import React from 'react'
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import DatePicker from 'react-multi-date-picker';
import { FormControl, FormLabel, useBreakpointValue } from '@chakra-ui/react';
import { ICustomDatePicker, datePickerInputStyles } from '../CustomDatePicker';

function CustomTimePicker({ label, calendarProps, formControlProps }: ICustomDatePicker) {

  const isMobile = useBreakpointValue({base: true, md: false})

  return (
    <FormControl {...formControlProps}>
        <FormLabel mb={0.5}>
          {label}
        </FormLabel>
        <DatePicker
            disableDayPicker
            format="HH:mm A"
            plugins={[
                <TimePicker hideSeconds />
            ]} 
            className={isMobile ? 'rmdp-mobile purple' : 'purple'}
            style={datePickerInputStyles}
            {...calendarProps}
        />
    </FormControl>
  )
}

export default CustomTimePicker