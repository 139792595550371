import React from 'react'
import { Textarea, FormControl, FormLabel, TextareaProps } from '@chakra-ui/react'

interface ITextArea {
    value: string,
    onChange: (val: string) => void,
    label: string,
    placeholder: string,
    variant?: TextareaProps['variant']
    textAreaProps?: TextareaProps
}

function TextAreaForm({value, onChange, label, placeholder, textAreaProps, variant='filled'}: ITextArea) {

    const handle_change = (val: string) => onChange(val)

  return (
    <FormControl>
        <FormLabel mb={0.5}>{label}</FormLabel>
        <Textarea 
            variant={variant}
            placeholder={placeholder}
            value={value}
            onChange={(e) => handle_change(e.target.value)}
            {...textAreaProps}
        />
    </FormControl>
  )
}

export default TextAreaForm