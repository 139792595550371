import React from 'react'
import { Routes, Route } from 'react-router-dom'
import ListEvents from '../views/Manage/EventsManager/ListEvents/ListEvents'
import CreateEvents from '../views/Manage/EventsManager/CreateEvents/CreateEvents'
import ManageEventDetails from '../views/Manage/EventsManager/EventDetails/ManageEventDetails'

function EventRouter() {
  return (
    <Routes>

        <Route index element={<ListEvents />} />
        
        <Route path='create' element={<CreateEvents />} />
        
        <Route path=':eventID' element={<ManageEventDetails />} />

    </Routes>
  )
}

export default EventRouter