import React from 'react'
import { Box, Hide, Center, useBreakpointValue } from '@chakra-ui/react'
import Header from '../header/Header'
import { SlideFade } from '@chakra-ui/react'
import { useHideOnScroll } from '../../hooks/useHideOnScroll'
import ManageMenu from './ManageMenu'

interface ISideBar {
    children: React.ReactElement
}

function SideBarManageBoard({ children }: ISideBar) {

  const { show } = useHideOnScroll()
  const isMobile = useBreakpointValue({base: true, md:false})

  return (
    <Box minH='100vh'>

      <Box
        as={SlideFade}
        initial={show}
        in={show}
        offsetY='-20px'
        position='relative'
        zIndex={30}
        bg={'white'}
      >

        <Header 
          other={isMobile ? <ManageMenu isMobile={true} /> : <></>}
        />

      </Box>

      <Hide below='md'>
        <Center
          position='fixed'
          top={0}
          bottom={0}
          zIndex={3}
          w='56px'
          bg='gray.50'
          alignItems={'center'}
          justifyContent='center'
        >

          <ManageMenu />

        </Center>
      </Hide>

      <Box ml={isMobile ? '' : '56px'}>

        { children }
      
      </Box>
  
    </Box>
  )
}

export default SideBarManageBoard