import { useState, useEffect } from 'react'
import axios from 'axios'
import { fetch_url } from '..'
import { useAppDispatch } from '../Store/hooks/redux'
import { updateUser } from '../Store/actions/user.actions'
import { AuthErrorTypes } from '../interfaces/errors.d'
import { useNavigate } from "react-router-dom";
import { useQueryParams } from '../utils/useQueryParams'
import { getPayloadFromToken } from '../utils/getPayloadFromToken'

export const useAuth = (isLogIn: boolean) => {
    const [ state, modify ] = useState<AuthTypes>(initialState)
    const [ error, setError ] = useState<AuthErrorTypes>(AuthErrorTypes.NO_ERROR)
    const [ isLoading, setLoading ] = useState(false)
    const [ googleOAuthURL, setGoogleURL] = useState('')    // Log In with Google
    let navigate = useNavigate();
    const dispatch = useAppDispatch()

    const { token: oauthToken } = useQueryParams()
    
    useEffect(() => {
        if (oauthToken){
            localStorage.setItem('token', oauthToken)
            const user = getPayloadFromToken(oauthToken)
            dispatch(updateUser({
                status: {isLoggedIn: true},
                data:{
                    _id: user._id,
                    first_name: user.first_name,
                    last_name: user.last_name,
                    email:user.email
                }
            }))
            setTimeout(() => {
                navigate('/manage')
            }, 100);
        }
    }, [oauthToken, navigate])
    

    // Getting url for OAuth with Google
    useEffect(() => {
      const loadOauthUrl = async () => {
        try {
            const response = await axios.get(`${fetch_url}/users/auth/google/url`)
            const { url } = response.data
            setGoogleURL(url)
        }
        catch (e) {
            console.log(e)
        }
      }
      loadOauthUrl()
    }, [])
    
    // Resetting when exiting views
    useEffect(() => {
      return () => {
        modify(initialState)
        setError(AuthErrorTypes.NO_ERROR)
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const setState = (update: Update<AuthTypes>) => {
        modify((prevState: AuthTypes) => ({...prevState, ...update}))
    }

    const submit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        console.log("Sending request...")
        
        const url = isLogIn ? `${fetch_url}/users/login` : `${fetch_url}/users/signup`
        const data = isLogIn ? {email:state.email, password: state.password} : {...state}
        const isValidPwd = isValidPassword(state.password)

        if (!isValidPwd && !isLogIn){
            setError(AuthErrorTypes.PASSWORD)
            return
        }
        setError(AuthErrorTypes.NO_ERROR)
        setLoading(true)
        

        axios.post(url, {...data})
        .then((res):AuthJSONResponse<IUser> => res.data)
        .then(json => {
            if (json.success){
                localStorage.setItem('token', json.token)
                setLoading(false)
                dispatch(updateUser({
                    status: {isLoggedIn: true},
                    data:{...json.data}
                }))
                navigate('/')
            }
            else {
                setError(AuthErrorTypes.WRONG)
                setLoading(false)
            }
        })
        .catch((e) => {
            const error: ErrorAuth = e.response.data
            setError(error.error)
            setLoading(false)
        })
    }

    return { state, setState, submit, error, setError, isLoading, googleOAuthURL }
}

const initialState: AuthTypes = {
    email:"",
    first_name:"",
    last_name:"",
    password:"",
}

const isValidPassword = (inputtxt: string) => {
    var passw = /^(?=.*\d)(?=.*[a-z]).{6,20}$/;
    if(inputtxt.match(passw)) { 
        return true;
    }
    else{ 
        return false;
    }
}
