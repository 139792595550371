import React from 'react'
import { Heading, Box } from '@chakra-ui/react'

function ListEvents() {
  return (
    <Box>
      <Heading>Events</Heading>
    </Box>
  )
}

export default ListEvents