import { useAppDispatch, useAppSelector } from "./redux"
import { getUserData, getUserStatus } from "../selectors/user.selectors"
import { updateUserStatus, updateUserData, userLogout, updateUser } from "../actions/user.actions"

export const useUser = () => {

    const dispatch = useAppDispatch()

    const user: IUser = useAppSelector(getUserData)
    const userStatus: IUserStatus = useAppSelector(getUserStatus)

    const updateStatus = (payload: IUserPayloadStatus) => dispatch(updateUserStatus(payload))
    const updateData = (payload: IUserPayloadData) => dispatch(updateUserData(payload))
    const logOut = () => dispatch(userLogout())
    const updateAllUser = (payload: IUserPayload) => dispatch(updateUser(payload))

    return { user, userStatus, updateStatus, updateData, logOut, updateAllUser }
}