import { useState } from 'react'

export const useCreateEvent = () => {
    const [ state, modify ] = useState<IEvent>(initialState)

    const setState = (update: Partial<IEvent>) => {
        modify(prevState => ({
            ...prevState,
            ...update
        }))
    }

    const setLocation = (update: Partial<ILocation>) => {
        modify(prevState => ({
            ...prevState,
            location: {
                ...prevState.location,
                ...update
            }
        }))
    }

    return { state, setState, setLocation }
}

const initialState: IEvent = {
    name: '',
    description:'',
    location: {
        type: "Point",
        coordinates: [],
        description:""
    },
    isOnline: false,
    dates: [],
    isPublished: false,
    ticketTypes: []
}